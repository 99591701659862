<template>
  <div>
    <b-breadcrumb class="mb-2">
      <b-breadcrumb-item to="/orders/punctual">Commandes</b-breadcrumb-item>
      <b-breadcrumb-item>
        <router-link :to="{
          name: 'admin-orders-punctual-details',
          params: { id: this.$route.params.id },
          query: { packageId: this.$route.query.packageId },
        }">Détails</router-link>
      </b-breadcrumb-item>
      <b-breadcrumb-item active>Offre</b-breadcrumb-item>
    </b-breadcrumb>
    <!-- Order assignment Modal  -->
    <b-card title="Faire une offre">
      <b-row>
        <b-col>
          <validation-observer #default="{}" ref="newOfferForm" tag="form">
            <!-- Form -->
            <b-form class="p-2" autocomplete="off" v-for="(form, index) in forms" :key="index">
              <b-form-group :label="`Professionnel fournissant le service de `" label-for="role">
                <validation-provider #default="{ errors }" name="pro" rules="required">
                  <b-form-select v-if="proOptions.length == 1 && proOptions[0].disabled" id="professional_id"
                    v-model="form.professional_id" :options="proOptions" />
                  <v-select v-else id="role" v-model="form.professional_id" :reduce="(professional) => professional.id"
                    label="full_name" :options="proOptions">
                    <template v-slot:no-options @click="$refs.select.open = false">
                      <span>Chargement ...</span>
                      <!-- <b-spinner
                          v-if="isGetServiceEmployeesActionLoading"
                          style="width: 2.5rem; height: 2.5rem"
                          class="align-middle text-info"
                        /> -->
                    </template>
                    <template #option="{ full_name, address, phone_number, profile_image }">
                      <b-row>
                        <b-col md="3" class="offset-md-3">
                          <b-avatar size="90px" rounded :src="profile_image" />
                        </b-col>
                      </b-row>
                      <br />
                      <b-row>
                        <b-col md="9">
                          <b-row>
                            <b-col>
                              <span class="text-white">
                                Nom & Prénom:
                                <strong class="text-info"> {{ full_name }} </strong>
                              </span>
                            </b-col>
                            <br />

                          </b-row>

                          <b-row>
                            <br />
                            <b-col>
                              <span class="text-white">
                                Numéro de téléphone :
                                <strong class="text-info">
                                  {{ phone_number != null ? phone_number : "Non renseigné" }}
                                </strong>
                              </span>
                            </b-col>
                            <br />

                            <b-col>
                              <span class="text-white">Address
                                <strong class="text-info">
                                  {{
                                    address != null
                                    ? address
                                    : "Non renseigné"
                                  }}</strong></span>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                      <hr />
                      <br />
                    </template>
                  </v-select>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <validation-provider #default="{ errors }" name="prix" rules="required">
                <b-form-group label="Prix" label-for="designation">
                  <b-form-input id="price" v-model="form.price" type="number" :state="errors.length > 0 ? false : null"
                    trim />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>


              <!-- Reapet form -->
              <div class="d-flex mb-2">
                <b-button type="reset" variant="danger" :disabled="forms.length == 1" @click="removeItem(index)">
                  Suprimer
                </b-button>
              </div>
            </b-form>
            <b-row>
          <!-- Reapet form -->

          <b-col cols="12" md="8" class="mb-4 d-flex align-items-center justify-content-start mb-2">
              <b-button variant="primary" @click="repeateAgain" class="ml-2">
                Autre offre
              </b-button>
            </b-col>
            <b-col @click="applyMakeOfferAction()" class="d-flex align-items-center justify-content-end mb-2" md="4">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-1"
            :disabled="isMakingOfferLoading">
            {{ !isMakingOfferLoading ? "Soumettre" : "Chargement..." }}
          </b-button>
        </b-col>
        </b-row>
            
          </validation-observer>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
  
<script>
import {
  BRow,
  BCol,
  BTab,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BTableLite,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BBadge,
  BPagination,
  BTooltip,
  BSpinner,
  BFormSelect,
  BForm,
  BCard,
  BTabs,
  BFormGroup,
  BFormTextarea,
  BBreadcrumb,
  BBreadcrumbItem,
} from "bootstrap-vue";
import { avatarText } from "@core/utils/filter";
import vSelect from "vue-select";
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store/index";

export default {
  components: {
    BSpinner,
    BTab,
    BRow,
    BCol,
    BFormInput,
    BCard,
    BTableLite,
    BButton,
    BTable,
    BMedia,
    BTabs,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BBadge,
    BPagination,
    BTooltip,
    BFormSelect,
    BForm,
    BFormGroup,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    VueGoodTable,
    vSelect,
    BBreadcrumb,
    BBreadcrumbItem,
  },

  data() {
    return {
      /* eslint-disable global-require */
      isGetServiceEmployeesActionLoading: true,
      employeeOptions: [],
      rowsDirectOrdersHaveNotProposal: [],
      columns: [
        {
          label: "Client",
          field: "user",
          filterOptions: {
            enabled: true,
            placeholder: "Rechercher...",
            filterFn(data, filterString) {
              return data.full_name.toLowerCase().includes(filterString.toLowerCase());
            },
          },
        },
        {
          label: "Service demandé",
          field: "recurring_service",
          filterOptions: {
            enabled: true,
            placeholder: "Rechercher...",
            filterFn(data, filterString) {
              return data.name.toLowerCase().includes(filterString.toLowerCase());
            },
          },
        },
        {
          label: "Budget",
          field: "budget",
        },
        {
          label: "Lieu",
          field: "address",
          filterOptions: {
            enabled: true,
            placeholder: "Rechercher...",
            filterFn(data, filterString) {
              return data.toLowerCase().includes(filterString.toLowerCase());
            },
          },
        },
        {
          label: "Traitée par",
          field: "assign_to",
          filterOptions: {
            enabled: true,
            placeholder: "Rechercher...",
            filterFn(data, filterString) {
              return data.full_name.toLowerCase().includes(filterString.toLowerCase());
            },
          },
        },
        {
          label: "Action",
          field: "action",
        },
      ],

      payload: {
        offers: []
      },

      isMakingOfferLoading: false,

      proOptions: [],
      forms: [
        {
          price: '',
          description: '',
          professional_id: '',
        },
      ],


      items: [],
      currentOrder: {},
      isLoadingEmployeesRecommended: false,
      total: "",
    };
  },

  computed: {
    ...mapGetters("orders", [
      "getDirectOrdersHaveNotProposal",
      "getDirectOrdersWhoNeedRecommandation",
    ]),
    ...mapGetters(["isDirectOrdersWhoNeedRecommandationLoading"]),
    ...mapGetters("auth", ["getUser"]),
    ...mapGetters("roles", ["getBusinessManagersList"]),

    metaDataHaveNotProposal() {
      const meta = {
        prev_page_url: "",
        next_page_url: "",
        current_page: "",
        last_page: "",
        current_page_url: "",
      };
      if (this.getDirectOrdersWhoNeedRecommandation != null) {
        meta.prev_page_url = this.getDirectOrdersWhoNeedRecommandation.prev_page_url;
        meta.next_page_url = this.getDirectOrdersWhoNeedRecommandation.next_page_url;
        meta.current_page = this.getDirectOrdersWhoNeedRecommandation.current_page;
        meta.last_page = this.getDirectOrdersWhoNeedRecommandation.last_page;
        meta.current_page_url = `${this.getDirectOrdersWhoNeedRecommandation.path}?page=${this.getDirectOrdersWhoNeedRecommandation.current_page}`;
      }
      return meta;
    },
  },

  watch: {
    isDirectOrdersWhoNeedRecommandationLoading(val) {
      if (val === false) {
        this.rowsDirectOrdersHaveNotProposal = this.getDirectOrdersHaveNotProposal;
        this.total = this.rowsDirectOrdersHaveNotProposal.length;
      }
    },
  },

  created() {

    this.applyGetServiceProfessionalsAction()
  },

  mounted() { },

  methods: {
    ...mapActions('services', ['getServiceProfessionalsAction']),
    ...mapActions('orders', [
      'makeOfferAction',
      'getOrderAction',
      'getOrdersAction',
      'deleteOrderAction',
      'deleteOrderOfferAction',
      'finishOrderAction',
      'getOrderOffersAction',
      'modifyOfferAction',
    ]),
    ...mapActions("professionals", ["deleteEmployeeRecommendationAction"]),

    applyGetServiceProfessionalsAction() {
      this.getServiceProfessionalsAction(this.$route.query.serviceId).then(
        response => {
          if (response.data.length === 0) {
            this.proOptions.push({
              value: '',
              disabled: true,
              text: "Vous n'avez pas de professionel pour ce service",
            })
          } else {
            response.data.forEach(element => {
              this.proOptions.push(element)
            })
          }
        },
      )
    },

    applyGetOrderOffersAction() {
      this.isOffersLoading = true
      this.getOrderOffersAction(this.order.id)
        .then(response => {
          this.offers = response.data
          // console.log('Liste des offres', this.offers)
          this.isOffersLoading = false
        })
        .catch(error => {
          this.isOffersLoading = false
        })
    },

    applyMakeOfferAction() {
      this.$refs.newOfferForm.validate().then(success => {
        if (success) {
          this.isMakingOfferLoading = true
          this.payload.offers = this.forms;

          this.makeOfferAction(
            {
              payload: this.payload,
              orderId: this.$route.params.id
            }
          )
            .then(() => {
              this.getOrderAction(this.$route.params.id)
                .then(async response => {
                  console.log('MAKE OFFER RESPONSE', response)
                  this.isMakingOfferLoading = false
                  await this.getOrdersAction()

                  this.$toast(
                    {
                      component: ToastificationContent,
                      props: {
                        title: 'Succès',
                        icon: 'CheckIcon',
                        text: 'Offre proposée avec succès',
                        variant: 'success',
                      },
                    },
                    {
                      position: 'top-center',
                    },
                  )

                  this.$router.push({
                    name: 'admin-orders-punctual-details',
                    params: { id: this.$route.params.id },
                  })
                  console.log(this.$route.params.id)
                })

            })
            .catch(error => {
              console.log(error)
              this.isMakingOfferLoading = false
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Error',
                    icon: 'AlertTriangleIcon',
                    text: error.response.data.message,
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-center',
                },
              )
            })
        }
      })
    },



    applyLoadMoreDirectOrdersWhoNeedRecommandationAction(url) {
      this.$store.commit("SET_IS_DIRECT_ORDERS_WHO_NEED_RECOMMANDATION_LOADING", true);
      this.rowsDirectOrdersHaveNotProposal = [];
      this.loadMoreDirectOrdersWhoNeedRecommandationAction(url)

        .then(() => {
          this.$store.commit(
            "SET_IS_DIRECT_ORDERS_WHO_NEED_RECOMMANDATION_LOADING",
            false
          );
        })
        .catch(() => {
          this.$store.commit(
            "SET_IS_DIRECT_ORDERS_WHO_NEED_RECOMMANDATION_LOADING",
            false
          );
        });
    },
    repeateAgain() {
      this.$refs.newOfferForm.validate().then((success) => {
        if (success) {
          this.forms.push({
            price: '',
            description: '',
            professional_id: '',
          });
        } else {
          reject();
        }
      });
    },

    removeItem(index) {
      this.forms.splice(index, 1);
    },




  },
};
</script>
  
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
  
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
  